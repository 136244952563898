<template>
  <div :class="cssClasses"><slot /></div>
</template>

<script>
export default {
  name: 'VueGridContainer',
  computed: {
    cssClasses() {
      const classes = [this.$style.container];

      return classes;
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: $container-padding;
}
</style>
